let socket: WebSocket | null = null;

export const socketService = {
  connect: (
    baseUrl: string,
    onMessage: (data: any) => void,
    onError: (error: string) => void
  ) => {
    if (!baseUrl) {
      throw new Error("WebSocket URL is missing in environment variables");
    }

    socket = new WebSocket(baseUrl);


    socket.onopen = () => {
      console.log("WebSocket connection opened 🚀");
    };

    socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      onMessage(response);
    };

    socket.onerror = (event) => {
      console.error("WebSocket error 🧨 :", event);
      onError("WebSocket connection error");
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed 🕊️");
    };
  },

  disconnect: () => {
    if (socket) {
      socket.close();
      console.log("WebSocket connection closed by client");
    }
  },
};
