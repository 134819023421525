import { atom } from 'recoil';

export interface User {
  x: number;
  y: number;
  size: number;
  displayName: string;
  userId: string;
  pictureUrl: string;
  language: string;
  beacons?: any[];
  channelId: string;
  createdAt?: string;
  updatedAt?: string;
}

export class userProfileDto {
	displayName!: string;
	userId!: string;
	pictureUrl!: string;
	language!: string;
	channelId!: string;
}

export const usersAtom = atom({
  key: 'UserAtom',
  default: [] as Array<User>,
});

