import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilState } from "recoil";
import { User, usersAtom } from "../recoil/bubble/atom";
import Bubble from "./Bubble";
import "../styles/BubbleContainer.css";

import RandomAvatarIcon from "../assets/icons/random_avatar.png";
import { fetchUsers } from "../services/userService";
import { socketService as SocketService } from "../services/socketService";
import Confetti from "react-confetti";

import clapSoundUrl from "../assets/sounds/clap.mp3";
import IntroSound from "../assets/sounds/intro.mp3";

const BubbleContainer: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const channelId = queryParams.get("channelId");

  const [bubbles, setBubbles] = useRecoilState(usersAtom);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userRandom, setUserRandom] = useState<User | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const currentAvatarBubble = useMemo(() => selectedUser, [selectedUser]);
  const currentBubbles = useMemo(() => bubbles, [bubbles]);
  const avatarSize = 100;

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_SOCKET_POST_URL;
    console.log("WebSocket URL:", baseUrl);
    console.log("API URL 🚀", process.env.REACT_APP_BASE_API_URL);

    if (!baseUrl) {
      console.log("WebSocket URL is missing in environment variables 🧨");

      return;
    }
    // const socketUrl = 'ws://showcase-socket.ex10.tech:8081'
    SocketService.connect(
      baseUrl,
      (response) => {
        const { type, room, data, uid } = response;
        if (
          type === process.env.REACT_APP_SOCKET_EVENT_TYPE &&
          room === process.env.REACT_APP_SOCKET_ROOM &&
          uid === channelId
        ) {
          const newBubble: User = {
            ...data,
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            size: avatarSize,
          };
          setBubbles((prevBubbles) => [...prevBubbles, newBubble]);
        }
      },
      (error) => {
        console.log("WebSocket connection error:", error);
      }
    );

    return () => {
      SocketService.disconnect();
    };
  }, [setBubbles]);

  useEffect(() => {
    // Replace with your WebSocket server URL
    const wsUrl = process.env.REACT_APP_SOCKET_POST_URL;
    if (!wsUrl) {
      console.log("WebSocket URL is missing in environment variables ");
      return;
    }
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log("Connected to WebSocket");
      ws.send(JSON.stringify({ type: "connect", message: "Hello Server" }));
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("Received message:", message);
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      ws.close();
    };
  }, []);

  const fetchData = useCallback(async (channelId: string) => {
    try {
      const { users } = await fetchUsers(channelId);
      const newBubbles = users.map((user) => ({
        ...user,
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: avatarSize,
      }));
      setBubbles(newBubbles);
    } catch (err) {
      console.log("Error fetching users data 🧨 : ", err);
    }
  }, []);

  useEffect(() => {
    if (channelId) {
      fetchData(channelId);
    } else {
      console.error("Channel ID is missing in query parameters 🧨");
    }
  }, [fetchData]);

  const randomSelect = () => {
    setShowConfetti(false);
    /* if (selectedUser !== null && bubbles.length > 1) {
      const bubbleFilters = bubbles.filter(
        (bubble) => bubble.userId !== currentAvatarBubble?.userId
      );
      if (bubbleFilters.length === 1) {
        setSelectedUser(bubbleFilters[0]);
        setBubbles(bubbleFilters);
        setShowConfetti(true);
        return;
      }
    } */
    setIsAnimating(true);
    setSelectedUser(null);
    setUserRandom(null);
  };

  useEffect(() => {
    if (isAnimating) {
      playIntroSound();
      const intervalId = setInterval(() => {
        const randomBubble = () =>
          bubbles[Math.floor(Math.random() * bubbles.length)];
        const newBubble = randomBubble();
        setUserRandom(newBubble);
      }, 600);

      setTimeout(() => {
        clearInterval(intervalId);
        setUserRandom(null);
        const randomBubble =
          bubbles[Math.floor(Math.random() * bubbles.length)];
        setIsAnimating(false);
        setUserRandom(randomBubble);
        setSelectedUser(randomBubble);
        setShowConfetti(true);

        playClapSound();
      }, 9500);

      return () => clearInterval(intervalId);
    }
  }, [isAnimating, bubbles]);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const playClapSound = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  const introAudioRef = useRef<HTMLAudioElement | null>(null);
  const playIntroSound = () => {
    if (introAudioRef.current) {
      introAudioRef.current.currentTime = 0;
      introAudioRef.current.play();
    }
  };

  return (
    <div className="container">
      <audio ref={audioRef} id="audio-sound">
        <source src={clapSoundUrl} type="audio/mp3" />
      </audio>
      <audio ref={introAudioRef} id="audio-sound">
        <source src={IntroSound} type="audio/mp3" />
      </audio>
      <div className="event-title">
        <p className="header">ZyGen 25th Anniversary</p>
        <p className="header">Innovating the Better Future</p>
        <p>with Your Trustworthy Partner</p>
      </div>
      <div>
        <div className="actions">
          <img
            className="button"
            src={RandomAvatarIcon}
            alt="random avatar logo"
            onClick={() => {
              if (isAnimating || currentBubbles.length === 0) return;
              randomSelect();
            }}
          />
        </div>
        <div className="content">
          {showConfetti && (
            <Confetti width={window.innerWidth} height={window.innerHeight} />
          )}
          {currentBubbles.map((bubble) => (
            <Bubble
              key={bubble.userId}
              id={parseInt(bubble.userId)}
              x={bubble.x}
              y={bubble.y}
              size={bubble.size}
              pictureUrl={bubble.pictureUrl}
              userId={bubble.userId}
              displayName={bubble.displayName}
              isSelected={bubble.userId === currentAvatarBubble?.userId}
              isAnimating={isAnimating}
              userIdRandom={userRandom?.userId === bubble.userId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BubbleContainer;
