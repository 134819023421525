import React from "react";
import { RecoilRoot } from "recoil";
import BubbleContainer from "./components/BubbleContainer";

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <BubbleContainer />
    </RecoilRoot>
  );
};

export default App;
