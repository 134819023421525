import React, { useEffect, useState } from "react";
import "../styles/Bubble.css";
import CrownImage from "../assets//images/crown.png";

interface BubbleProps {
  id: number;
  x: number;
  y: number;
  size: number;
  pictureUrl: string;
  userId: string;
  displayName: string;
  isSelected: boolean;
  isAnimating: boolean;
  userIdRandom: boolean;
}

const Bubble: React.FC<BubbleProps> = ({
  id,
  x,
  y,
  size,
  pictureUrl,
  userId,
  displayName,
  isSelected,
  isAnimating,
  userIdRandom = false,
}) => {
  const [position, setPosition] = useState({ x, y });
  const [scale, setScale] = useState(1);
  const [velocity, setVelocity] = useState({
    directionX: Math.random() < 0.5 ? -1 : 1,
    directionY: Math.random() < 0.5 ? -1 : 1,
    speed: Math.random() * 2 + 1,
  });

  useEffect(() => {
    let animationFrameId: number;

    const moveBubble = () => {
      setPosition((prev) => {
        let newX = prev.x + velocity.directionX * velocity.speed;
        let newY = prev.y + velocity.directionY * velocity.speed;

        if (newX <= 0) {
          newX = 0;
          setVelocity((v) => ({ ...v, directionX: 1 }));
        } else if (newX + size >= window.innerWidth) {
          newX = window.innerWidth - size;
          setVelocity((v) => ({ ...v, directionX: -1 }));
        }

        if (newY <= 0) {
          newY = 0;
          setVelocity((v) => ({ ...v, directionY: 1 }));
        } else if (newY + size >= window.innerHeight) {
          newY = window.innerHeight - size;
          setVelocity((v) => ({ ...v, directionY: -1 }));
        }

        return { x: newX, y: newY };
      });

      animationFrameId = requestAnimationFrame(moveBubble);
    };

    moveBubble();

    return () => cancelAnimationFrame(animationFrameId);
  }, [velocity, size]);

  useEffect(() => {
    if (isSelected) {
      setScale(2.8);
    } else {
      setScale(1);
    }
  }, [isSelected]);

  return (
    <div
      className={`bubble  ${isAnimating ? "animating" : ""} ${
        isSelected ? "selected" : ""
      }`}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: `${size}px`,
        height: `${size}px`,
        transform: `${!isSelected ? `scale(${scale}` : "none"})`,
        backgroundImage: `url(${pictureUrl})`,
      }}
    >
      {isAnimating && userIdRandom && <div className="spinner" />}
      {!isAnimating && isSelected && (
        <img src={CrownImage} alt="crown logo" className="crown" />
      )}
      {!isAnimating && isSelected && (
        <div className="displayName">{displayName}</div>
      )}
    </div>
  );
};

export default Bubble;
