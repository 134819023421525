import axios from "axios";
interface User {
  _id: string;
  displayName: string;
  userId: string;
  pictureUrl: string;
  language: string;
  beacons: any[];
  channelId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface FetchUsersResponse {
  count: number;
  users: User[];
}

export const fetchUsers = async (
  channelId: string
): Promise<FetchUsersResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/users/${channelId}`;
    const response = await axios.get<FetchUsersResponse>(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error fetching users data:", error);
    throw error;
  }
};
